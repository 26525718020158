@tailwind base;
@tailwind components;
@tailwind utilities;

html, body{
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
}

@keyframes transparent {
    0%{
        opacity: 100%;
    }
    100%{
        opacity: 0%;
    }
}